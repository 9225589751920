<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <div class="row">
        <div class="col-xl-4 col-lg-4">
          <stats-card
            title="TOPLAM EKSPERTİZ"
            type="gradient-blue"
            v-bind:sub-title="dashboard.vehicle_instance_done_count_total"
            icon="ni ni-active-40"
            class="mb-4 mb-xl-0"
          >
          </stats-card>
        </div>
        <div class="col-xl-4 col-lg-4">
          <stats-card
            title="BU AY EKSPERTİZ"
            type="gradient-green"
            v-bind:sub-title="dashboard.vehicle_instance_done_count_month"
            icon="ni ni-money-coins"
            class="mb-4 mb-xl-0"
          >
          </stats-card>
        </div>
        <div class="col-xl-4 col-lg-4">
          <stats-card
            title="BEKLEYEN EKSPERTİZ"
            type="gradient-red"
            v-bind:sub-title="dashboard.vehicle_instance_pending_count_total"
            icon="ni ni-fat-remove"
            class="mb-4 mb-xl-0"
          >
          </stats-card>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--7">
    <div class="row">
      <div class="col">
        <div class="card shadow bg-default">
          <div
            class="card-header border-0 bg-transparent"
          >
            <div class="row align-items-center">
              <div class="col">
                <h3 class="mb-0 text-white">
                  Son Ekspertizler
                </h3>
              </div>
              <div class="col text-right">
                <router-link to="/expertise" class="btn btn-primary  btn-sm">
                  <i class="ni ni-bullet-list-67"></i>
                  <span>Tümünü Görüntüle</span>
                </router-link>
              </div>
            </div>
          </div>

          <div class="table-responsive table-overflowed">
            <base-table
              class="table align-items-center table-flush"
              tbody-classes="list"
              :thead-classes="'thead-dark'"
              :class="'table-dark'"
              :data="latestExpertises"
            >
              <template v-slot:columns>
                <th>Plaka</th>
                <th>Model Yılı</th>
                <th>Marka</th>
                <th>Seri</th>
                <th>Şube</th>
                <th>Başlangıç Tarihi</th>
                <th>Bitiş Tarihi</th>
                <th>Durum</th>
              </template>

              <template v-slot:default="row">
                <th scope="row">
                  {{ row.item.plate_number }}
                </th>
                <td>
                  {{ row.item.model }}
                </td>
                <td >
                  {{ row.item.make_name }}
                </td>
                <td>
                  {{ row.item.serie_name }}
                </td>
                <td >
                  {{ row.item.branch_name }}
                </td>
                <td>
                  {{ row.item.created_at }}
                </td>
                <td>
                  {{ row.item.escalated_at }}
                </td>
                <td>
                  <span class="badge badge-dot mr-4" :class="`badge-${row.item.status.class}`">
                    <i :class="`bg-${row.item.status.class}`"></i>
                    <span class="status">{{ row.item.status.title }}</span>
                  </span>
                </td>
              </template>
            </base-table>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import ExpertiseService from "../services/expertise.service";
import ReportService from "../services/report.service";

export default {
  components: {

  },
  created: function () {
    ExpertiseService.getAll(10).then(
      (expertises) => {
        this.latestExpertises = expertises.data.data;
    });
    ReportService.getDashboard().then(
      (dashboard) => {
        this.dashboard = dashboard.data;
    });
  },
  data() {
    return {
      latestExpertises: [],
      dashboard: [],
    };
  },
  methods: {
  },
  mounted() {
  },
};
</script>
<style></style>
