<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>
    <div class="container-fluid mt--7">
    <div class="row">
      <div class="col">
        <div class="card shadow bg-default">
          <div
            class="card-header border-0 bg-transparent"
          >
            <div class="row align-items-center">
              <div class="col">
                <h3 class="mb-0 text-white">
                  Ekspertiz Listesi <span class="badge bg-primary">{{totalExpertises}}</span>
                </h3>
              </div>
              <div class="row text-center">
                <h3 class="mb-0 text-white mr-2 cursor-pointer" @click="clearFilter(index)" v-for="(filterItem, index) in forms.filter" :key="`filterItem-${index}`">
                  <span class="badge bg-primary" v-if="index == 'status' && filterItem !== ''">
                    {{forms.filterMap[index]}}: {{filterItem == 0 ? "Bekliyor" : "Tamamlandı"}} <i class="ni ni-fat-remove"></i>
                  </span>
                  <span class="badge bg-primary" v-else-if="filterItem ">
                    {{forms.filterMap[index]}}: {{filterItem}}  <i class="ni ni-fat-remove"></i>
                  </span>
                </h3>
              </div>
              <div class="col text-right">
                <base-button type="primary" size="sm" @click="modals.confirm = true;"><i class="ni ni-check-bold"></i>Oto.net Transfer</base-button>
                <base-button type="primary" size="sm" @click="excelExport"><i class="ni ni-cloud-download-95"></i> Excel</base-button>
                <base-button type="primary" size="sm" @click="modals.filter = true"><i class="ni ni-ui-04"></i> Filtrele</base-button>
              </div>
            </div>
          </div>

          <div class="table-responsive">
            <base-table
              class="table align-items-center table-flush"
              tbody-classes="list"
              :thead-classes="'thead-dark'"
              :class="'table-dark'"
              :data="latestExpertises"
            >
              <template v-slot:columns>
                <th>Plaka</th>
                <th>Model Yılı</th>
                <th>Marka</th>
                <th width="20">Seri</th>
                <th>Şube</th>
                <th>Başlangıç Tarihi</th>
                <th>Bitiş Tarihi</th>
                <th>Transfer Tarihi</th>
                <th>Durum</th>
                <th></th>
              </template>

              <template v-slot:default="row">
                <th scope="row">
                  {{ row.item.plate_number }}
                </th>
                <td>
                  {{ row.item.model }}
                </td>
                <td >
                  {{ row.item.make_name }}
                </td>
                <td>
                  {{ row.item.serie_name }}
                </td>
                <td >
                  {{ row.item.branch_name }}
                </td>
                <td>
                  {{ row.item.created_at }}
                </td>
                <td>
                  {{ row.item.escalated_at }}
                </td>
                <td>
                  {{ row.item.transfer_at }}
                </td>
                <td>
                  <span class="badge badge-dot mr-4" :class="`badge-${row.item.status.class}`">
                    <i :class="`bg-${row.item.status.class}`"></i>
                    <span class="status">{{ row.item.status.title }}</span>
                  </span>
                </td>
                <td>
                  <router-link :to="{ name: 'EKSPERTİZ DETAY', params: { id: row.item.id }}" class="btn btn-success btn-sm">
                    <i class="ni ni-bold-right"></i>
                    <span>Detay</span>
                  </router-link>
                </td>
              </template>
            </base-table>
          </div>
          <div
            class="card-footer d-flex justify-content-center"
            :class="'bg-transparent'"
          >
            <base-pagination align="center" v-bind:total="totalExpertises" v-bind:perPage="perPage" v-bind:value="pageValue" @input="paginationWatcher"></base-pagination>
          </div>
          <modal v-model:show="modals.confirm">
               <template v-slot:header>
                  <h5 class="modal-title">Oto.net Transfer</h5>
               </template>
                <div>
                  <strong>{{totalExpertises}} tane aracı transfer etmek istediğinize emin misiniz?</strong>
                </div>
               <template v-slot:footer>
                 <base-button type="primary" size="md" @click="refreshExpertises(otonet_transfer = 1);modals.confirm = false; modals.transfer = true;">Evet</base-button>
                 <base-button type="secondary" size="md" @click="modals.confirm = false">Kapat</base-button>
               </template>
            </modal>
          <modal v-model:show="modals.filter">
               <template v-slot:header>
                  <h5 class="modal-title">Filtrele</h5>
               </template>
                <div>
                   <form role="form">
                    <base-input
                      formClasses="input-group-alternative"
                      placeholder="Plaka"
                      v-model="forms.filter.plate_number"
                    >
                    </base-input>
                    <div class="d-flex">
                      <base-input
                        formClasses="input-group-alternative mr-3"
                        :type="type"
                        placeholder="Başlangıç Tarihi"
                        v-model="forms.filter.start_date"
                        @focus="convertToDate()"
                      >
                      </base-input>
                      <base-input
                        formClasses="input-group-alternative ml-3"
                        :type="type1"
                        placeholder="Bitiş Tarihi"
                        v-model="forms.filter.end_date"
                        @focus="convertToDate1()"
                      >
                      </base-input>
                    </div>
                    <select class="custom-select" v-model="forms.filter.status">
                      <option v-bind:value="''">Tümü</option>
                      <option v-bind:value="0">Bekliyor</option>
                      <option v-bind:value="1">Tamamlandı</option>
                    </select>
                  </form>
                </div>
               <template v-slot:footer>
                 <base-button type="secondary" @click="modals.filter = false">Kapat</base-button>
                 <base-button type="primary" @click="submitFilterForm()">Filtrele</base-button>
               </template>
            </modal>
            <modal v-model:show="modals.transfer">
               <template v-slot:header>
                  <h5 class="modal-title">Oto.net Transfer Durumu</h5>
               </template>
                <div>
                  <VueElementLoading
                    :spinner="spinnerKind"
                    :size="spinnerSize"
                    :duration="spinnerDuration"
                    :color="spinnerColor"
                    :active="fullscreen"
                    is-full-screen
                    v-if="!transferResults"
                  />
                  <base-table
                    class="table align-items-center table-flush"
                    tbody-classes="list"
                    :thead-classes="'thead-light'"
                    :class="'table-light'"
                    :data="transferResults"
                    v-if="transferResults"
                  >
                    <template v-slot:columns>
                      <th>Plaka</th>
                      <th>Durum</th>
                    </template>
                     <template v-slot:default="row">
                      <td v-if="row.item.success">
                        <span class="badge badge-dot" :class="`badge-success`">
                          <span class="status">{{ row.item.plate_number }}</span>
                        </span>
                      </td>
                      <td v-else>
                        <span class="badge badge-dot" :class="`badge-warning`">
                          <span class="status">{{ row.item.plate_number }}</span>
                        </span>
                      </td>
                      <td v-if="row.item.success">
                        <span class="badge badge-dot" :class="`badge-success`">
                          <i :class="`bg-success`"></i>
                          <span class="status">{{ row.item.message }}</span>
                        </span>
                      </td>
                      <td v-else>
                        <span class="badge badge-dot" :class="`badge-warning`">
                          <i :class="`bg-warning`"></i>
                          <span class="status">{{ row.item.message }}</span>
                        </span>
                      </td>
                    </template>
                </base-table>
                </div>
               <template v-slot:footer>
                 <base-button type="secondary" @click="modals.transfer = false">Kapat</base-button>
               </template>
            </modal>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import Modal from "@/components/Modal.vue";
import VueElementLoading from "vue-element-loading";
import ExpertiseService from "../services/expertise.service";

export default {
  components: {
    Modal,
    VueElementLoading
  },
  created: function () {
    this.refreshExpertises();
  },
  data() {
    return {
      type: "text",
      type1: "text",
      filter: [],
      latestExpertises: [],
      transferResults: [],
      totalExpertises: 0,
      perPage: 20,
      pageValue: 1,
      fullscreen: true,
      spinnerKind: 'bar-fade-scale',
      spinnerColor: '#2DCE89',
      spinnerSize: '90',
      spinnerDuration: '1',
      modals: {
        filter: false,
        transfer: false,
        confirm: false
      },
      forms: {
        filter: {
          plate_number: '',
          start_date: '',
          end_date: '',
          status: ''
        },
        filterMap: {
          plate_number: 'Plaka',
          start_date: 'Başlangıç Tarihi',
          end_date: 'Bitiş Tarihi',
          status: 'Durum'
        }
      }
    };
  },
  methods: {
    paginationWatcher(event) {
      this.pageValue = event;
      this.refreshExpertises();
    },
    refreshExpertises(otonet_transfer = null) {
      if (otonet_transfer) {
        this.transferResults = null;
      }

      ExpertiseService.getAll(this.perPage, this.pageValue, this.forms.filter.plate_number, this.forms.filter.start_date, this.forms.filter.end_date, this.forms.filter.status, null, otonet_transfer).then(
        (expertises) => {
          this.latestExpertises = expertises.data.data;
          this.totalExpertises = expertises.data.total;
          this.transferResults = expertises.data.transfer_result;
      });
    },
    submitFilterForm() {
      this.refreshExpertises();
      this.modals.filter = false;
    },
    excelExport() {
      ExpertiseService.getAllExcel(this.forms.filter.plate_number, this.forms.filter.start_date, this.forms.filter.end_date).then(
        (expertises) => {
          expertises
      });
    },
    convertToDate() {
      this.type = "date";
    },
    convertToDate1() {
      this.type1 = "date";
    },
    clearFilter(filterItem) {
      this.forms.filter[filterItem] = '';
      this.refreshExpertises();
    }
  },
  mounted() {
  },
};
</script>
<style></style>
